<div id="imagen_servicio" class="animate__animated animate__slideInLeft">
  <p id="text_image">{{ Data.titulo }}</p>
</div>

<div class="container-contact-info">
  <div class="container-social-media">
    <br />
    <h5>CONTACT</h5>

    <a href="tel:9932359203" target="_blank">
      <p class="link-social-media">
        <i style="color: darkcyan" class="fas fa-phone-alt"></i>
        <b style="color: black"> {{ Data.celular }} México: </b> +52 993 235 9203
      </p>
    </a>

    <a href="tel:6265946900" target="_blank">
      <p class="link-social-media">
        <i style="color: darkcyan" class="fas fa-phone-alt"></i>
        <b style="color: black"> {{ Data.celular }} USA: </b>+1 626 594 6900
      </p>
    </a>

    <a href="mailto:contact@dnsgp.com" target="_blank">
      <p class="link-social-media">
        <i style="color: red" class="fas fa-envelope"></i>
        <b style="color: black"> {{ Data.correo }}:</b> contact@dnsgp.com
      </p>
    </a>

    <br />
    <br />
    <h5>SOCIAL MEDIA</h5>

    <div class="container-social-media-icons">
      <a target="_blank">
        <i style="color: blue" class="fab fa-facebook"></i>
      </a>

      <a target="_blank">
        <i style="color: rgb(255, 0, 166)" class="fab fa-instagram"></i>
      </a>

      <a target="_blank">
        <i style="color: green" class="fab fa-whatsapp"></i>
      </a>
    </div>
  </div>
</div>

<div id="container_mapa" style="text-align: center">
  <google-map
    id="mapa"
    width="100%"
    height="50vh"
    [zoom]="5"
    [center]="center_mapa"
  >
    <map-marker [position]="position"> </map-marker>

    <map-marker [position]="position2"> </map-marker>
  </google-map>
</div>

<select style="display: none" id="select_inicio">
  <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
  <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>
