<section id="section_nosotros">
  <div id="div_tit_nosotros">
    <p class="animated slideInUp" id="tit_nosotros">{{ Data.titulo }}</p>
    <!-- <div id="linea"></div> -->
  </div>
  <div id="texto_nosotros" class="wow animate__animated animate__fadeInLeft">
    <p id="p_nosotros">
      {{ Data.descripcion1 }}
      <br />

      {{ Data.descripcion2 }}
      <br />
    </p>

    <p id="p_nosotros">
      <b>{{ Data.mision }}:</b> <br />

      {{ Data.descripcion_mision }}.<br />
      <br />

      <b>{{ Data.vision }}:</b> <br />
      {{ Data.descripcion_vision }}
      <br />
    </p>
  </div>

  <div id="comodin"></div>

  <div
    id="foto_nosotros"
    class="wow animate__animated animate__fadeInRight"
  ></div>
</section>

<select style="display: none" id="select_inicio">
  <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
  <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>
