<p id="p_certificaciones">{{ Data.titulo }}</p>

<div id="div_certificacion_wrapper">
  <div
    id="certificacion_wrapper"
    *ngFor="let Item of Filter_Items_certificacion"
  >
    <div id="titulo_certificacion" [ngSwitch]="tab">
      <p *ngSwitchCase="1" id="p_certificacion">{{ Item.name_spanish }}</p>
      <p *ngSwitchCase="2" id="p_certificacion">{{ Item.name_english }}</p>
    </div>

    <div
      id="imagen_certificacion"
      [ngStyle]="{
        'background-image':
          'url(https://dnsgp.com/api/images/' + Item.imagenes[0] + ')'
      }"
    ></div>
  </div>
</div>

<select style="display: none" id="select_inicio">
  <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
  <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>
