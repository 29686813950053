import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
})
export class ClientesComponent implements OnInit {
  tab: number = 1;
  Data: any = {};
  input_id_trabajos: any;
  input_tipo_trabajos: any;

  Items_panel_imagenes: any[] = [];
  Filter_Items_clientes: any[] = [];
  Items_panel_trabajos: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    this.Cargar_Idioma();
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Items_panel_imagenes = await this._servicio.GetData(
      `${this._servicio.HOST}multimedia/get`
    );
    console.log('Items_panel: ', this.Items_panel_imagenes);

    this.Items_panel_trabajos = await this._servicio.GetData(
      `${this._servicio.HOST}themes/get`
    );
    for (var i = 0; i < this.Items_panel_trabajos.length; i++) {
      this.Items_panel_trabajos[i].imagenes = [];
      for (var j = 0; j < this.Items_panel_imagenes.length; j++) {
        if (
          this.Items_panel_imagenes[j].id_theme ==
          this.Items_panel_trabajos[i].id
        ) {
          this.Items_panel_trabajos[i].imagenes.push(
            this.Items_panel_imagenes[j].url
          );
        }
      }
    }

    this.Filter_Items_clientes = this.Items_panel_trabajos.filter(function (
      el
    ) {
      return el.type_action === '4';
    });
    console.log('Filter_Items_clientes: ', this.Filter_Items_clientes);
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }
  Cambiar_Espanol() {
    this.tab = 1;
    this.Data = {
      titulo: 'Conozca nuestras empresas',
      subtitulo: 'Esfuerzos colaborativos de DNS',
    };
  }

  Cambiar_Ingles() {
    this.tab = 2;
    this.Data = {
      titulo: 'Get to know our companies',
      subtitulo: 'Collaborative DNS efforts',
    };
  }
}
