import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-certificaciones',
  templateUrl: './certificaciones.component.html',
  styleUrls: ['./certificaciones.component.css'],
})
export class CertificacionesComponent implements OnInit {
  tab: number = 1;
  Data: any = {};

  Items_panel_imagenes: any[] = [];
  Filter_Items_certificacion: any[] = [];
  Items_panel_trabajos: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    this.Cargar_Idioma();
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Items_panel_imagenes = await this._servicio.GetData(
      `${this._servicio.HOST}multimedia/get`
    );
    console.log('Todas las imagenes: ', this.Items_panel_imagenes);

    this.Items_panel_trabajos = await this._servicio.GetData(
      `${this._servicio.HOST}themes/get`
    );

    console.log('Todos los temas: ', this.Items_panel_imagenes);

    for (var i = 0; i < this.Items_panel_trabajos.length; i++) {
      this.Items_panel_trabajos[i].imagenes = [];
      for (var j = 0; j < this.Items_panel_imagenes.length; j++) {
        if (
          this.Items_panel_imagenes[j].id_theme ==
          this.Items_panel_trabajos[i].id
        ) {
          this.Items_panel_trabajos[i].imagenes.push(
            this.Items_panel_imagenes[j].url
          );
        }
      }
    }

    console.log('Items_panel_trabajos 2: ', this.Items_panel_trabajos);

    this.Filter_Items_certificacion = this.Items_panel_trabajos.filter(
      function (el) {
        return el.type_action === '3';
      }
    );

    console.log(
      'Temas Filtrados: ',
      this.Filter_Items_certificacion
    );
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    // console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.tab = 1;
    this.Data = {
      titulo: 'Certificaciones',
    };
  }

  Cambiar_Ingles() {
    this.tab = 2;
    this.Data = {
      titulo: 'Certifications',
    };
  }
}
