import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dns-security',
  templateUrl: './dns-security.component.html',
  styleUrls: ['./dns-security.component.css'],
})
export class DnsSecurityComponent implements OnInit {
  Data: any = {};
  Texto_Idioma: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Cargar_Idioma();
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;

    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      tema1: `¿Qué ofrecemos?`,

      descripcion1: `Nos especializamos en sistemas de vigilancia, control de acceso, intrusión, fibra óptica, cableado estructurado, redes, sistemas de audio, Iluminación y electricidad para uso comercial y residencial.`,
      tema2: `Servicios`,

      servicio1: `Alarma contra incendios`,
      servicio3: `Camaras de videovigilancia`,
      servicio4: `Central de monitoreo para alarmas y camaras`,
      servicio5: `Controles de acceso`,
      servicio6: `Monitoreo 24/7`,
      servicio7: `Electricidad`,

      tema3: `Industrias que trabajamos`,
      industria1: `Hoteles`,
      industria2: `Hospitales`,
      industria3: `Escuelas`,
      industria4: `Comercial`,
      industria5: `Industrial`,

      tema4: `Mas servicios`,
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      tema1: `What we offer?`,
      descripcion1: `We specialize in surveillance systems, access control, intrusion, fiber optics, structured cabling, networks, audio systems, lighting and electrical for commercial and residential.`,

      tema2: `Services`,
      servicio1: `Fire Alarm`,
      servicio3: `Video surveillance cameras`,
      servicio4: `Central monitoring for alarms and cameras`,
      servicio5: `Access controls`,
      servicio6: `24/7 monitoring`,
      servicio7: `Electricity`,

      tema3: `Industries we work with`,
      industria1: `Hotels`,
      industria2: `Hospitals`,
      industria3: `Schools`,
      industria4: `Commercial`,
      industria5: `Industrial`,

      tema4: `More services`,
    };
  }
}
