<div id="imagen_servicio" class="animate__animated animate__slideInLeft">
  <p id="text_image">{{ Data.projectTitle }}</p>
</div>

<div class="container-abount-us">
  <h3 class="title-about-us">{{ Data.projectTitle }}</h3>

  <div class="container-line"></div>

  <p id="project-description">
    {{ Data.projectDescription }}
  </p>
</div>

<div class="cointainer-cards">
  <div class="project-card">
    <div class="project-image" id="bee-apartments-lodi"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Bee Apartments - Lodi - CA - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>Bee Apartments</p>
            <p class="card-title">Units</p>
            <p>8 residential apartments with mixed use floor</p>
            <p class="card-title">Stories</p>
            <p>4 floors</p>
            <p class="card-title">YEAR</p>
            <p>Coming soon 2025</p>
            <p class="card-title">SERVICES</p>
            <p>
              Architecture, structural design, MEP Design, exterior renderings
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="quinta-tracy"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            La Quinta Inn - Tracy - CA - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>La Quinta Inn</p>
            <p class="card-title">FRANCHISE</p>
            <p>Wyndham Hotels</p>
            <p class="card-title">YEAR</p>
            <p>Coming soon 2024</p>
            <p class="card-title">SERVICES</p>
            <p>Fire alarm</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="staybridge-suites-1"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Staybridge Suites - Manteca - CA - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>Staybridge Suites</p>
            <p class="card-title">FRANCHISE</p>
            <p>IHG</p>
            <p class="card-title">YEAR</p>
            <p>2024</p>
            <p class="card-title">SERVICES</p>
            <p>Electric low voltage, communication and surveillance system</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="HOME2_SUITES_1"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            HOME2 SUITES - American Canyon - CA - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>HOME2 SUITES</p>
            <p class="card-title">FRANCHISE</p>
            <p>Hilton</p>
            <p class="card-title">YEAR</p>
            <p>2023</p>
            <p class="card-title">SERVICES</p>
            <p>Electric low voltage and communication.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="fairfield-winnemucca"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Fairfield Inn & Suites - Winnemucca - NV - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>Fairfield Inn & Suites</p>
            <p class="card-title">FRANCHISE</p>
            <p>Marriott</p>
            <p class="card-title">YEAR</p>
            <p>2021</p>
            <p class="card-title">SERVICES</p>
            <p>
              Electric low voltage, communication, surveillance system and audio
              system
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="home2-roswell"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            HOME2 SUITES - Roswell - NM - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>HOME2 SUITES</p>
            <p class="card-title">FRANCHISE</p>
            <p>Hilton</p>
            <p class="card-title">YEAR</p>
            <p>2020</p>
            <p class="card-title">SERVICES</p>
            <p>
              Electric low voltage, communication, surveillance system and audio
              system
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="staybridge-ames"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Staybridge Suites - Ames - IA - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>Staybridge Suites</p>
            <p class="card-title">FRANCHISE</p>
            <p>IHG</p>
            <p class="card-title">YEAR</p>
            <p>2020</p>
            <p class="card-title">SERVICES</p>
            <p>
              Electric low voltage, communication, surveillance system and audio
              system
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="best-western-carlsbad"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Best Western - Carlsbad - NV -USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>Best Western</p>
            <p class="card-title">FRANCHISE</p>
            <p>Best Western</p>
            <p class="card-title">YEAR</p>
            <p>2020</p>
            <p class="card-title">SERVICES</p>
            <p>Underground utilities, communication and surveillance system</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="holliday-inn-liberal"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Holliday Inn - Liberal - KS - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>Holliday Inn</p>
            <p class="card-title">FRANCHISE</p>
            <p>IHG</p>
            <p class="card-title">YEAR</p>
            <p>2018</p>
            <p class="card-title">SERVICES</p>
            <p>
              Electric low voltage, communication, surveillance system and audio
              system
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="project-card">
    <div class="project-image" id="fairfield-riverside"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            Fairfield Inn & Suites - Riverside - CA - USA
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p class="card-title">Project</p>
            <p>Fairfield Inn & Suites</p>
            <p class="card-title">FRANCHISE</p>
            <p>Marriott</p>
            <p class="card-title">YEAR</p>
            <p>2018</p>
            <p class="card-title">SERVICES</p>
            <p>Electric low voltage and communication</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<select style="display: none" id="select_inicio">
  <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
  <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>
