<div id="container_galeria">
     <div id="informacion_wrapper" ng-switch="myVar">

          <div [ngSwitch]="tab">
               <div id="foto_logo" *ngSwitchCase="1">

               </div>
          </div>



          <div class="div_texto">
               <label id="label_titulo">Jonathan Hernández</label>
          </div>


          <div class="div_texto">
               <label class="label_subtitulo" id="label_subtitulo2"><i style="color:red"
                         class="fas fa-map-marker-alt"></i> Los Ángeles,
                    California</label>
          </div>


          <div class="div_texto">
               <label class="label_subtitulo" id="label_subtitulo2"><i style="color:red"
                         class="fas fa-map-marker-alt"></i> Los Mochis,
                    Sinaloa</label>
          </div>

          <div class="div_texto" id="div_desc">
               <label class="label_subtitulo" id="label_subtitulo3">
                    {{Data.descripcion}}
               </label>
          </div>

          <div class="div_texto" style="text-align: center;">
               <label class="label_subtitulo">__________________________________________</label>
          </div>

          <div class="div_texto">
               <label class="label_subtitulo" style="font-weight: bold;" id="label_subtitulo1">{{Data.seguro}}</label>
          </div>

          <div class="div_texto">
               <label class="label_subtitulo" id="label_subtitulo1"><i style="color:green"
                         class="fas fa-check-circle"></i>{{Data.caracteristica1}}</label>
          </div>

          <div class="div_texto">
               <label class="label_subtitulo" id="label_subtitulo1"><i style="color:green"
                         class="fas fa-check-circle"></i>{{Data.caracteristica2}}</label>
          </div>

          <div class="div_texto">
               <label class="label_subtitulo" id="label_subtitulo1"><i style="color:green"
                         class="fas fa-check-circle"></i>{{Data.caracteristica3}}</label>
          </div>


     </div>

     <div id="fotos_wrapper">
          <div id="div_trabajos">
               <label id="fotos_trabajo">{{Data.titulo}}</label>
          </div>

          <!-- <div id="div_botones">
               <button (click)="fotos_telecom()" class="btn btn-primary botones">Trabajos Telecomunicaciones</button>
               <button (click)="fotos_security()" class="btn btn-primary botones">Trabajos Seguridad</button>
               <button (click)="fotos_construction()" class="btn btn-primary botones">Trabajos Construccion</button>
          </div> -->

          <div id="div_fotos">

               <div class="item_foto" data-toggle="modal" data-target="#exampleModal"
                    *ngFor="let Item of Filter_Items_trabajos" (click)="cargar_carrousel(Item.id)">

                    <div [ngStyle]="{'background-image': 'url(https://dnsgp.com/api/images/' + Item.imagenes[0] + ')'}"
                         id="foto">
                    </div>

                    <i class="fas fa-image" id="icon_imagen"></i>
                    <label id="label_imagen">{{Item.imagenes.length}}</label>
               </div>

               <!-- <div class="item_foto" data-toggle="modal" data-target="#exampleModal">
                    <img src="../../assets/Imagenes/imagen_ejemplo.jpeg" id="foto">
                    <i class="fas fa-image" id="icon_imagen"></i>
                    <label id="label_imagen">1</label>
               </div> -->

          </div>
     </div>
</div>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
     <div class="modal-dialog modal-lg">
          <div class="modal-content">
               <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true" style="font-size: 2em; color: whitesmoke;">&times;</span>
                    </button>
               </div>
               <div class="modal-body">

                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                         <div class="carousel-inner">

                              <div *ngFor="let Item of Items_panel_carrousel; let i = first"
                                   [ngClass]="{'carousel-item': true,'active': i}">
                                   <img id="imagen_item" class="d-block w-100"
                                        src="https://dnsgp.com/api/images/{{Item.url}}" alt="First slide">

                              </div>

                         </div>
                         <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                              data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                         </a>
                         <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                              data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                         </a>
                    </div>
               </div>
          </div>
     </div>
</div>

<select style="display:none;" id="select_inicio">
     <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
     <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>