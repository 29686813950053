import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css'],
})
export class GaleriaComponent implements OnInit {
  Data: any = {};

  tab: number = 1;

  Items_panel_imagenes: any[] = [];
  Filter_Items_trabajos: any[] = [];
  Items_panel_trabajos: any[] = [];
  Items_panel_carrousel: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    this.Cargar_Idioma();
    await this.cargarDatos();
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      titulo: `Trabajos Realizados`,
      descripcion: `GRUPO DNS ofrece una amplia gama de servicios de consultoría con las 
      herramientas y la experiencia necesarias para hacer crecer su negocio.`,
      seguro: `Seguros`,
      caracteristica1: `Compensación de trabajadores`,
      caracteristica2: `Responsabilidad general`,
      caracteristica3: `Calidad de trabajo`,
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      titulo: `Work done`,
      descripcion: `GRUPO DNS offers a wide range of consulting services with the tools
       and experience necessary to grow your business.`,
      seguro: `insurance`,
      caracteristica1: `Workers compensation`,
      caracteristica2: `Overall responsibility `,
      caracteristica3: `Quality of work`,
    };
  }

  async cargarDatos() {
    this.Items_panel_imagenes = await this._servicio.GetData(
      `${this._servicio.HOST}multimedia/get`
    );
    console.log('Items_Imagenes: ', this.Items_panel_imagenes);

    this.Items_panel_trabajos = await this._servicio.GetData(
      `${this._servicio.HOST}themes/get`
    );
    for (var i = 0; i < this.Items_panel_trabajos.length; i++) {
      this.Items_panel_trabajos[i].imagenes = [];
      for (var j = 0; j < this.Items_panel_imagenes.length; j++) {
        if (
          this.Items_panel_imagenes[j].id_theme ==
          this.Items_panel_trabajos[i].id
        ) {
          this.Items_panel_trabajos[i].imagenes.push(
            this.Items_panel_imagenes[j].url
          );
        }
      }
    }

    console.log('Items_panel_trabajos 2: ', this.Items_panel_trabajos);

    this.Filter_Items_trabajos = this.Items_panel_trabajos.filter(function (
      el
    ) {
      return el.type_action === '2';
    });

    console.log('Filter_Items_clientes: ', this.Filter_Items_trabajos);
  }

  fotos_security() {
    this.tab = 1;
  }

  fotos_telecom() {
    this.tab = 2;
  }

  fotos_construction() {
    this.tab = 3;
  }

  async cargar_carrousel(id_trabajo) {
    // alert(convet.toString(id));
    var id: string = String(id_trabajo);

    this.Items_panel_carrousel = this.Items_panel_imagenes.filter(function (
      el
    ) {
      return el.id_theme === id;
    });

    console.log('Items_panel_imagenes', this.Items_panel_imagenes);

    console.log('Items_panel_carrousel', this.Items_panel_carrousel);
  }
}
