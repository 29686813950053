import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dns-projects',
  templateUrl: './dns-projects.component.html',
  styleUrls: ['./dns-projects.component.css'],
})
export class DnsProjectsComponent implements OnInit {
  Data: any = {};
  Texto_Idioma: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  
  async ngOnInit() {
    window.scroll(0, 0);
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Cargar_Idioma();
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      projectTitle: 'PROYECTOS',
      projectDescription: `El personal de Grupo DNS ha trabajado alrededor del mundo. Esta experiencia nos ha proporcionado una amplia gama de conocimientos en estándares de calidad, especificaciones y mejores prácticas internacionales para el diseño. Algunos de los proyectos mencionados en esta sección destacan el trabajo realizado por el personal del Grupo DNS para diferentes empresas.`,
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      projectTitle: `PROJECTS`,
      projectDescription: `Grupo DNS staff have worked around the globe. This experience has provided us with a wide range of expertise in quality standards, specifications and international best practices for design. Some of the projects mentioned in this section highlight work done by Grupo DNS staff for different firms.`,
    };
  }
}
