
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dns-acercade',
  templateUrl: './dns-acercade.component.html',
  styleUrls: ['./dns-acercade.component.css']
})
export class DnsAcercadeComponent implements OnInit {
  Data: any = {};

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.Cargar_Idioma();
  }


  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma')).value;
    console.log("Oninit INICIO==>", btn_idioma);
    if (btn_idioma === "ES") {
      this.Cambiar_Espanol();
    } else if (btn_idioma === "EN") {
      this.Cambiar_Ingles();
    }
  }



  Cambiar_Espanol() {
    this.Data = {
      titulo: `Nosotros`,
      descripcion1: `GRUPO DNS ofrece una amplia gama de servicios de consultoría con las herramientas y la experiencia necesarias para hacer crecer su negocio.`,
      descripcion2: `Trabajamos junto con nuestros clientes de principio a fin centrándonos en sus necesidades,
       a la vez que producimos nuevas ideas, desarrollamos estrategias efectivas, y diseñamos soluciones escalables de alta calidad.`,
      mision: "Misión",
      descripcion_mision: `Superar las expectativas de nuestros clientes, tomando estas bases;
      servicio; personalizado, eficiencia, calidad y precios competitivos, creando
      así, experiencias inolvidables`,
      vision: "Visión",
      descripcion_vision: `Ser una empresa consciente de la competitividad, teniendo como distintivo la
      calidez y amabilidad del personal y de nuestros valores en GRUPO DNS.`
    }
  }

  Cambiar_Ingles() {
    this.Data = {
      titulo: `About us`,
      descripcion1: `GRUPO DNS offers a wide range of consulting services with the tools and experience necessary to grow your business.`,
      descripcion2: `We work together with our clients from start to finish focusing on their needs,
      as we produce new ideas, develop effective strategies, and design high-quality, scalable solutions.`,
      mision: "Mission",
      descripcion_mision: `Exceed the expectations of our clients, taking these bases;
      service; personalized, efficiency, quality and competitive prices`,
      vision: "Vision",
      descripcion_vision: `Being a company aware of competitiveness, having as a distinctive the
      warmth and kindness of the staff and our values ​​in GRUPO DNS.`
    }
  }

}








