<div id="container_component">
  <div id="imagen_servicio" class="animate__animated animate__slideInLeft">
    <p id="text_image">DNS CONSTRUCTION</p>
  </div>

  <div id="imagen_logo" class="animate__animated animate__slideInUp"></div>

  <div class="animate__fadeInLeft animate__animated" id="div_text_servicio">
    <label class="Tema wow animate__animated animate__fadeInLeft">
      {{ Data.tema1 }}
    </label>

    <label class="titulo wow animate__animated animate__fadeInLeft">
      {{ Data.titulo1 }}
    </label>
    <p id="text_servicio" class="wow animate__animated animate__fadeInLeft">
      {{ Data.descripcion1 }}
    </p>
  </div>

  <div id="div_text_servicio" class="animate__animated animate__slideInUp">
    <label class="Tema wow animate__animated animate__fadeInLeft">
      {{ Data.tema2 }}
    </label>

    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio1 }}
      </p>
    </div>

    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio2 }}
      </p>
    </div>

    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio3 }}
      </p>
    </div>

    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio4 }}
      </p>
    </div>

    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio5 }}
      </p>
    </div>
    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio6 }}
      </p>
    </div>
    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio7 }}
      </p>
    </div>
    <div id="servicio_item" class="wow animate__animated animate__fadeInLeft">
      <p id="p_item_servicio">
        <i class="fas fa-check-circle"></i>{{ Data.servicio8 }}
      </p>
    </div>
  </div>

  <div id="div_imagenes">
    <div class="container-projects">
      <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="5"
            aria-label="Slide 6"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="6"
            aria-label="Slide 7"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="7"
            aria-label="Slide 8"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../../assets/Imagenes/excavacion.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio1 }}</h5>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Construction/Servicios/tractor.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio2 }}</h5>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Construction/Servicios/curado-del-concreto.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio3 }}</h5>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/demolicion.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio4 }}</h5>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/underground-utilities.png"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio5 }}</h5>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Newconstruction.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio6 }}</h5>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/remodeling.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio7 }}</h5>
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Construction/Servicios/plumbing.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="filtro"></div>

            <div class="carousel-caption d-none d-md-block">
              <h5>{{ Data.servicio8 }}</h5>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <!-- <div id="carouselExampleCaptions" class="carousel slide">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../../assets/Imagenes/Construction//Servicios/curado-del-concreto.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block"></div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Construction/Servicios/tractor.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block"></div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Construction/Servicios/grua.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block"></div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Construction/Servicios/alberca.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block"></div>
          </div>
          <div class="carousel-item">
            <img
              src="../../assets/Imagenes/Construction/Servicios/plumbing.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block"></div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> -->
    </div>
  </div>

  <div
    id="div_text_servicio2"
    class="wow animate__animated animate__fadeInLeft"
  >
    <label class="Tema wow animate__animated animate__fadeInLeft">
      {{ Data.tema3 }}
    </label>

    <div
      id="item_industrias"
      class="wow animate__animated animate__fadeInRight"
    >
      <div id="div_circulo">
        <i id="i_circulo" class="fas fa-hotel"></i>
      </div>
      <label id="label_industria">
        {{ Data.industria4 }}
      </label>
    </div>

    <div id="item_industrias" class="wow animate__animated animate__fadeInLeft">
      <div id="div_circulo">
        <i id="i_circulo" class="fas fa-city"></i>
      </div>
      <label id="label_industria">
        {{ Data.industria5 }}
      </label>
    </div>

    <div id="item_industrias" class="wow animate__animated animate__fadeInLeft">
      <div id="div_circulo">
        <i id="i_circulo" class="fas fa-gopuram"></i>
      </div>
      <label id="label_industria">
        {{ Data.industria1 }}
      </label>
    </div>

    <div
      id="item_industrias"
      class="wow animate__animated animate__fadeInRight"
    >
      <div id="div_circulo">
        <i id="i_circulo" class="fas fa-hospital"></i>
      </div>
      <label id="label_industria">
        {{ Data.industria2 }}
      </label>
    </div>

    <div
      id="item_industrias"
      class="wow animate__animated animate__fadeInLeft ultimo_circulo"
    >
      <div id="div_circulo">
        <i id="i_circulo" class="fas fa-school"></i>
      </div>
      <label id="label_industria">
        {{ Data.industria3 }}
      </label>
    </div>
  </div>

  <div id="div_text_servicio" class="wow animate__animated animate__fadeInUp">
    <label class="Tema wow animate__animated animate__fadeInLeft">
      {{ Data.tema4 }}
    </label>
  </div>
  <div id="servicios_wrapper">
    <div
      [routerLink]="['/dns/dnsconstruction']"
      id="item_servicio"
      class="wow animate__animated animate__fadeInLeft"
    >
      <div id="servicio_dns_construction">
        <p id="text_image2">DNS SECURITY</p>
      </div>
    </div>

    <div
      [routerLink]="['/dns/dnssecurity']"
      id="item_servicio"
      class="wow animate__animated animate__fadeInRight"
    >
      <div id="servicio_dns_telecom">
        <p id="text_image2">DNS TELECOM</p>
      </div>
    </div>
  </div>
</div>

<select style="display: none" id="select_inicio">
  <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
  <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>
