import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clientes-fotos',
  templateUrl: './clientes-fotos.component.html',
  styleUrls: ['./clientes-fotos.component.css'],
})
export class ClientesFotosComponent implements OnInit {
  tab: number = 1;
  tab2: number = 1;
  Data: any = {};

  Items_panel_imagenes: any[] = [];
  Filter_Items_trabajos: any[] = [];
  Items_panel_trabajos: any[] = [];
  Items_panel_carrousel: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    this.Cargar_Idioma();
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Items_panel_imagenes = await this._servicio.GetData(
      `${this._servicio.HOST}multimedia/get`
    );
    console.log('Items_Imagenes: ', this.Items_panel_imagenes);

    this.Items_panel_trabajos = await this._servicio.GetData(
      `${this._servicio.HOST}themes/get`
    );
    for (var i = 0; i < this.Items_panel_trabajos.length; i++) {
      this.Items_panel_trabajos[i].imagenes = [];
      for (var j = 0; j < this.Items_panel_imagenes.length; j++) {
        if (
          this.Items_panel_imagenes[j].id_theme ==
          this.Items_panel_trabajos[i].id
        ) {
          this.Items_panel_trabajos[i].imagenes.push(
            this.Items_panel_imagenes[j].url
          );
        }
      }
    }

    console.log('Items_panel_trabajos 2: ', this.Items_panel_trabajos);

    this.Filter_Items_trabajos = this.Items_panel_trabajos.filter(function (
      el
    ) {
      return el.type_action === '1';
    });

    console.log('Filter_Items_clientes: ', this.Filter_Items_trabajos);
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.tab = 1;
    this.Data = {
      titulo_prin: 'NUESTROS PROYECTOS',
      subtitulo: 'Proyectos completados:',
      subtitulo2: 'Estos son solo algunos de nuestros proyectos:',
      titulo: 'Portafolio',
      galeria: 'Ver Galeria',
    };
  }

  Cambiar_Ingles() {
    this.tab = 2;
    this.Data = {
      titulo_prin: 'OUR PROJECTS',
      subtitulo: 'Completed Projects:',
      subtitulo2: 'Here are just some of our projects:',
      titulo: 'Portfolio',
      galeria: 'View Gallery',
    };
  }

  cambiar_pantalla(id_trabajo) {
    this.tab2 = 2;
    var id: string = String(id_trabajo);

    this.Items_panel_carrousel = this.Items_panel_imagenes.filter(function (
      el
    ) {
      return el.id_theme === id;
    });
  }

  imagen_back() {
    this.tab2 = 1;
  }
}
